import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Sealing = () => (
  <Layout>
    <Helmet title="Sealing" />
    <BgImage image="/assets/pool-surrounds.jpg" className="top">
      <h1>Sealing</h1>
    </BgImage>
    <section className="content">
      <p>
        The right sealing is important to protect your pool surrounds. Whether
        you plan to protect your brand-new deck from salt staining, or you are
        renovating an old surface, we are able to help.
      </p>
      <ul>
        <li>Salt Resistant Sealing</li>
        <li>Oil Resistant Sealers</li>
        <li>Water Resistant Sealers</li>
        <li>Colour Enhancer Sealers</li>
      </ul>
    </section>
  </Layout>
)

export default Sealing
